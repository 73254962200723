<template>
  <div v-show="visible" class="dialog">
    <div class="dialog-content" :class="size">
      <div class="close" @click="close()">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>window-close</title><path fill="#000" d="M13.46,12L19,17.54V19H17.54L12,13.46L6.46,19H5V17.54L10.54,12L5,6.46V5H6.46L12,10.54L17.54,5H19V6.46L13.46,12Z" /></svg>
      </div>
      <div v-if="pdfUrl" id="viewer" style="width: 100%; height: 100%">
        <WebViewer ref="webViewer" :initial-doc="pdfUrl" :editable="editable" :redactions="redactions" @saveAction="(blob, fileName) => webViewerSaveAction(blob, fileName)" />
      </div>
      <div v-else-if="imageSrc" class="image">
        <img :src="imageSrc" alt="decorative image">
      </div>
      <div v-show="title || subtitle" class="dialog-text" :style="imageSrc ? '' : 'margin-top: 0;'">
        <div v-show="title" class="title" v-html="title" />
        <div v-if="!imageSrc && roundIcon" class="icon-container">
          <div class="icon" :style="{ backgroundColor: bgIcon ? '#f0f8ff' : 'transparent' }">
            <img :src="roundIcon" alt="icon">
          </div>
        </div>
        <div v-show="subtitle" class="subtitle" v-html="subtitle" />
        <div v-if="input" class="dialog-input">
          <div class="group group-100">
            <input
              id="inputstring"
              v-model="inputString"
              autocomplete="off"
              type="text"
              class="inputMaterial"
            >
            <span class="bar" />
            <label>{{ inputstringtitle }}</label>
          </div>
        </div>
      </div>
      <material-button
        v-show="buttonText"
        small
        class="dialogButton"
        :class="{disabledDialogButton: input? inputString==='': false}"
        :text="buttonText"
        :type="success ? success: 'success'"
        @click="emitAction()"
      /> 
    </div>
  </div>
</template>

<script>
import { ref, toRefs } from '@vue/reactivity'
import WebViewer from './WebViewer.vue'

export default {
  components: {
    WebViewer
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    subtitle: {
      type: String,
      default: ''
    },
    buttonText: {
      type: String,
      default: ''
    },
    imageSrc: {
      type: String,
      default: ''
    },
    pdfUrl: {
      type: String,
      default: ''
    },
    size: {
      type: String,
      default: ''
    },
    roundIcon: {
      type: String,
      default: ''
    },
    editable: {
      type: Boolean,
      default: false
    },
    redactions: {
      type: Object,
      default: () => ({
        name: null,
        lastname: null,
        email: null
      })
    },
    bgIcon: {
      type: Boolean,
      default: false
    },
    input: {
      type: Boolean,
      default: false
    },
    inputstringtitle: {
      type: String,
      default: ''
    },
    success: {
      type: String,
      default: ''
    }
  },
  emits: ['buttonAction', 'saveAction'],
  setup(props, { emit }) {
    let visible = ref(false)
    const inputString = ref('')

    const webViewer = ref('webViewer')

    const close = () => {
      inputString.value = ''
      visible.value = false
    }

    const open = (pdf) => {
      visible.value = true

      if (pdf && webViewer && webViewer.value && typeof webViewer.value.refresh === 'function') webViewer.value.refresh(pdf)
    }

    const emitAction = () => {
      if(!props.input){
        emit('buttonAction')
      } else {
        emit('buttonAction', { text: inputString })
      }
    }

    const webViewerSaveAction = (blob, fileName) => emit('saveAction', blob, fileName)

    return {
      visible,
      inputString,
      close,
      open,
      emitAction,
      webViewerSaveAction,
      webViewer,
      ...toRefs(props)
    }
  }
}
</script>

<style lang="scss" scoped>
@use '../assets/scss/dialog';

</style>
