<template>
  <div id="webviewer" ref="viewer" style="height: 100%" />
</template>

<script>
import WebViewer from '@pdftron/webviewer'
import QrScanner from 'qr-scanner'

export default {
  // https://www.npmjs.com/package/@pdftron/webviewer
  name: 'WebViewer',
  props: {
    initialDoc: { type: String },
    editable: { type: Boolean },
    redactions: {
      type: Object,
      default: () => ({
        name: null,
        lastname: null,
        email: null
      })
    }
  },
  data() {
    return {
      imageCounter: 0,
      fillColor: null
    }
  },
  mounted() {
    this.generateWebViewer()
  },
  methods: {
    async refresh(pdfUrl) {
      const wvInstance = WebViewer.getInstance(document.getElementById('webviewer'))

      if (wvInstance) {
        wvInstance.UI.loadDocument(pdfUrl)
      } else {
        this.generateWebViewer()
        console.log('No webviewer instances')
      }
    },

    generateWebViewer() {
      WebViewer(
        {
          path: `${process.env.BASE_URL}webviewer`,
          initialDoc: this.initialDoc,
          fullAPI: true,
          disableLogs: true,
          enableRedaction: this.editable, //https://www.pdftron.com/documentation/web/guides/redaction-setup/
          licenseKey: process.env.VUE_APP_PDFTRONLICENSEKEY
        },
        document.getElementById('webviewer')
      ).then(async (instance) => {
        const { documentViewer, annotationManager, Annotations, PDFNet, Math } =
          instance.Core

        this.fillColor = new Annotations.Color(150, 150, 150)

        if (this.editable) {
          const searchListener = async (searchPattern, options, results) => {
            this.generateAutoRedactionAlerts(results.map((m) => m.resultStr))

            // add redaction annotation for each search result
            let newAnnotations = results.map(({ pageNum, quads }) => {
              const annotation = new Annotations.RedactionAnnotation()
              annotation.PageNumber = pageNum
              annotation.Quads = quads.map((quad) => quad.getPoints())
              // annotation.StrokeColor = new Annotations.Color(255, 255, 255);
              annotation.FillColor = this.fillColor
              return annotation
            })

            // #region links redaction
            for (const { PageNumber, rect } of annotationManager
              .getAnnotationsList()
              .filter((f) => f instanceof Annotations.Link)) {
              const annotation = new Annotations.RedactionAnnotation()
              annotation.PageNumber = PageNumber
              annotation.Quads = [rect.toQuad()]
              annotation.FillColor = this.fillColor
              newAnnotations.push(annotation)
            }
            // #endregion

            annotationManager.addAnnotations(newAnnotations)
            await annotationManager.drawAnnotationsFromList(newAnnotations)

            //instance.UI.removeSearchListener(searchListener);
            instance.UI.searchText('')
            documentViewer.clearSearchResults()
          }

          documentViewer.addEventListener('documentLoaded', async () => {
            this.onWebViewerLoaded(instance, searchListener)

            try {
              setTimeout(async () => {
                await PDFNet.initialize(process.env.VUE_APP_PDFTRONLICENSEKEY)
                const doc = await documentViewer.getDocument().getPDFDoc()
                const reader = await PDFNet.ElementReader.create()

                // Read page content on every page in the document
                const itr = await doc.getPageIterator()
                let pageCounter = 1

                for (itr; await itr.hasNext(); itr.next()) {
                  // Read the page
                  const page = await itr.current()
                  reader.beginOnPage(page)
                  await this.imageExtract(
                    reader,
                    PDFNet,
                    Annotations,
                    pageCounter,
                    annotationManager,
                    Math,
                    documentViewer.getDocument()
                  )
                  reader.end()
                  pageCounter++
                }
              }, 1000)
            } catch (err) {
              console.log(err)
            }
          })

          instance.UI.enableElements(['contentEditButton'])

          // https://www.pdftron.com/documentation/web/guides/customizing-header/#adding-a-custom-save-button
          instance.UI.setHeaderItems((header) => {
            header.push({
              type: 'actionButton',
              img: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M0 0h24v24H0z" fill="none"/><path d="M17 3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V7l-4-4zm-5 16c-1.66 0-3-1.34-3-3s1.34-3 3-3 3 1.34 3 3-1.34 3-3 3zm3-10H5V5h10v4z"/></svg>',
              onClick: async () => {
                await annotationManager.applyRedactions()

                const doc = documentViewer.getDocument()
                const xfdfString = await annotationManager.exportAnnotations()
                const data = await doc.getFileData({
                  // saves the document with annotations in it
                  xfdfString,
                  flatten: true
                })
                const arr = new Uint8Array(data)
                const blob = new Blob([arr], {
                  type: 'application/pdf'
                })

                // send request to api
                this.$emit('saveAction', blob, arr.name)
              }
            })
          })
        } else {
          // https://www.pdftron.com/documentation/web/guides/customizing-header/
          instance.UI.disableElements(['toolbarGroup-View'])
        }
        instance.UI.disableElements(['toolbarGroup-Shapes'])
        instance.UI.disableElements(['toolbarGroup-Edit'])
        instance.UI.disableElements(['toolbarGroup-Insert'])
        instance.UI.disableElements(['toolbarGroup-Annotate'])
        instance.UI.disableElements(['toolbarGroup-FillAndSign'])
        instance.UI.disableElements(['toolbarGroup-Forms'])
      })
    },

    generateAutoRedactionAlerts(redactedStrings) {
      // alert if nothing has been redacted
      if (redactedStrings.length === 0) {
        this.$toast.warning('Non è stato anonimizzato nulla!')
        return
      }

      // alert if lastname has not been redacted
      if (!redactedStrings.find((f) => f == this.redactions.lastname))
        this.$toast.warning('Non è stato anonimizzato il cognome!')

      // alert if name has not been redacted
      if (!redactedStrings.find((f) => f == this.redactions.name))
        this.$toast.warning('Non è stato anonimizzato il nome!')
    },

    async imageExtract(
      reader,
      PDFNet,
      Annotations,
      pageNumber,
      annotationManager,
      math,
      doc
    ) {
      let element
      while ((element = await reader.next()) !== null) {
        switch (await element.getType()) {
          case PDFNet.Element.Type.e_image:
          case PDFNet.Element.Type.e_inline_image:
            const ctm = await element.getCTM()
            let x2 = 1,
              y2 = 1
            const result = await ctm.mult(x2, y2)
            x2 = result.x
            y2 = result.y

            //console.log('%c    x1=' + ctm.m_h.toFixed(2) + ', y1=' + ctm.m_v.toFixed(2) + ', x2=' + x2.toFixed(2) + ', y2=' + y2.toFixed(2), 'background: #222; color: #bada55')

            const annotation = new Annotations.RedactionAnnotation({
              FillColor: this.fillColor,
              PageNumber: pageNumber,
              Rect: new math.Rect(
                ctm.m_h,
                doc.getPDFCoordinates(pageNumber, x2, y2).y,
                x2,
                doc.getPDFCoordinates(
                  pageNumber,
                  ctm.m_h.toFixed(2),
                  ctm.m_v.toFixed(2)
                ).y
              )
            })

            if ((await element.getType()) == PDFNet.Element.Type.e_image) {
              const image = await PDFNet.Image.createFromObj(
                await element.getXObject()
              )
              const filter = await PDFNet.Filter.createMemoryFilter(
                65536,
                false
              )
              const writer = await PDFNet.FilterWriter.create(filter)
              await image.exportAsPngFromStream(writer)
              await writer.flush()
              await filter.memoryFilterSetAsInputFilter()
              const reader = await PDFNet.FilterReader.create(filter)
              const pngBuffer = await reader.readAllIntoBuffer()

              QrScanner.scanImage(
                new Blob([pngBuffer], { type: 'image/png' }),
                {}
              ).then(async () => {
                annotationManager.addAnnotations([annotation])
                await annotationManager.drawAnnotationsFromList([annotation])
              })
            }

            break
          case PDFNet.Element.Type.e_form: // Process form XObjects
            reader.formBegin()
            await this.imageExtract(reader, PDFNet)
            reader.end()
            break
        }
      }
    },

    async onWebViewerLoaded(instance, searchListener) {
      // mail
      var searchPattern = '([a-zA-Z0-9+._-]+@[a-zA-Z0-9._-]+\\.[a-zA-Z0-9_-]+)' //ciao@come.va google@gmail.com test203@yahoo.com

      //strings like firstname lastname ...
      searchPattern +=
        '|' +
        this.redactions.name +
        '|' +
        this.redactions.lastname +
        '|' +
        this.redactions.email

      // phone
      // 291 555 5555
      // 3331234415
      // 333 1234415
      // 333 123 4415
      // +39 3331234415
      // +123 3331234415
      // +123 5556 4455
      // +393331234415
      // +39 333 1234415
      // +39 333 123 4415
      searchPattern += '|(\\+\\d{1,3}\\s)?\\(?\\d{3}\\)?[\\s.-]?\\d{3,4}[\\s.-]?\\d{4,6}'

      // 0039 3930861829
      searchPattern += '|\\d{5,}'

      // 0444 650101
      // 0444 65 01 01
      searchPattern += '|\\d{4}[ -]\\d{6,8}|\\d{4}[ -]\\d{2}[ -]\\d{2}[ -]\\d{2}'

      // fiscal code
      searchPattern += '|^([A-Z]{6}[0-9LMNPQRSTUV]{2}[ABCDEHLMPRST]{1}[0-9LMNPQRSTUV]{2}[A-Z]{1}[0-9LMNPQRSTUV]{3}[A-Z]{1})$|([0-9]{11})$'

      // external links http://google.com www.google.com cving.com http://www.education.gov.yk.ca/
      // https://community.pdftron.com/t/how-do-i-modify-all-hyper-links-in-an-existing-pdf-document-to-simple-text/426
      // (http|ftp|https):\/\/([\w_-]+(?:(?:\.[\w_-]+)+))([\w.,@?^=%&:\/~+#-]*[\w@?^=%&\/~+#-])
      // /(?:(?:https?|ftp|file):\/\/|www\.|ftp\.)(?:\([-A-Z0-9+&@#\/%=~_|$?!:,.]*\)|[-A-Z0-9+&@#\/%=~_|$?!:,.])*(?:\([-A-Z0-9+&@#\/%=~_|$?!:,.]*\)|[A-Z0-9+&@#\/%=~_|$])/igm
      // (?:(?:https?|ftp):\/\/)?[\w/\-?=%.]+\.[\w/\-&?=%.]+
      // [-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)
      searchPattern +=
        '|(https?:\\/\\/(?:www\\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\\.[^\\s]{2,}|www\\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\\.[^\\s]{2,}|https?:\\/\\/(?:www\\.|(?!www))[a-zA-Z0-9]+\\.[^\\s]{2,}|www\\.[a-zA-Z0-9]+\\.[^\\s]{2,})'

      // #region dates anonimyzer
      //const yearToRedact = (new Date().getFullYear() - 18).toString().slice(-2); // '85 '98 ecc

      // regex that finds all dates
      //		(\\d{1,4}([.\\- / ])\\d{1,2}([.\\-/ ])\\d{1,4})

      // Settembre 2022
      // 27 settembre 2022
      // 27/09/2022
      // 27-09-2022
      // 27 09 2022
      // 27\09\2022
      // 27.09.2022

      // setTimeout(() => {
      // 	instance.UI.addSearchListener(searchListener);
      // 	instance.UI.searchText(
      // 		"(\\d{1,4}([.\\- / ])\\d{1,2}([.\\-/ ])\\d{1,4})",
      // 		searchOptions
      // 	);
      // }, 100);
      searchPattern += '|(\\d{1,4}([.\\-/\\s\\\\])\\d{1,2}([.\\-/\\s\\\\])\\d{1,4})'
      //await annotationManager.applyRedactions();
      // #endregion

      // via ciaone 23
      // via ciaone 23/a
      // via Ciaone ciao 23
      // via ciaone, 23
      // via ciaone,23
      // strada dei sassolini parlanti 22
      // strada dei sassolini parlanti, 22
      // Via L. Muratori 7
      // Via Ariberto 18/c – 24040 Arzago d’Adda ( Bg )
      searchPattern +=
        '|((via|strada|piazza) [a-z A-Z.]{5,25}(,| |, )\\d{1,4}((/[a-zA-Z])?))'

      const searchOptions = {
        caseSensitive: false, // match case
        wholeWord: true, // match whole words only
        wildcard: false, // allow using '*' as a wildcard value
        regex: true, // string is treated as a regular expression
        searchUp: false, // search from the end of the document upwards
        ambientString: true // return ambient string as part of the result
      }

      instance.UI.removeSearchListener(searchListener)
      instance.UI.addSearchListener(searchListener)

      // start search after document loads
      instance.UI.searchTextFull(searchPattern, searchOptions)
    }
  }
}
</script>

<style>
#webviewer {
  height: 100vh;
}
</style>
